/* Tabs.module.css */

.tabs {
  display: flex;
  flex-direction: column;
  width: fit-content; /* This will make the container adjust to the content width */
  align-items: flex-start; /* Align tabs to the left */
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  font-family: 'Rosarivo', serif;
  font-size: 14px;
}

.tabs h2 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

.tabs a {
  text-decoration: none;
  color: #333;
  padding: 10px 0px;
  transition: background-color 0.3s ease;
}

.tabs a.active {
  color: rgb(135, 6, 6); /* Change the color of the active link */
}

.verticalTab {
  display: block;
  margin-bottom: 10px;
}

/* FOR MOBILE MENU */
.mobileMenu {
  display: none;
  color: rgb(135, 6, 6);
}

@media (max-width: 768px) {
  .desktopMenu {
    display: none;
  }

  .mobileMenu {
    display: block;
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 1000;
  }

  .mobileMenuOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .mobileMenuContent {
    position: fixed;
    top: 0;
    right: 0;
    width: 150px;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }

  .mobileMenuTabs {
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
  }

  .mobileMenuTab {
    padding: 10px;
    text-decoration: none;
    color: #333;
  }
}

