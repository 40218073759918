/* App.module.css */
.app {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
  color: rgb(135, 6, 6);
  font-size: 18px;
  text-decoration: none;
  padding-top: .5rem;
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80vw;
  margin-top: 0; /* Remove the top margin */
}

@media (max-width: 768px) {
  /* Styles for mobile devices */
  .mobileTitle {
    display: block; /* Show the mobile title on mobile devices */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: rgb(135, 6, 6);
    padding: 15px;
    text-align: left;
    z-index: 1000;
    background-color: white;
  }
}
