.navigationContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  align-self: flex-start; /* Add this line */
}

.navigationLink {
  color: #000;
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navigationLink:hover {
  color: rgb(135, 6, 6);
}

.imageIndexText {
  color: #000;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-style: italic;
}

.photographsText {
  padding: 0.5rem 1rem;
}

.gridToggleIcon {
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: auto; /* Add this line */
}

.gridToggleIcon:hover {
  color: rgb(135, 6, 6);
}

.separator {
  color: #000;
}