.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: minmax(250px, auto);
  max-width: 1200px;
  width: 65vw;
}

.gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: .5rem;
}

.gridImage {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: absolute;
}

.gridToggleIcon {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: auto;
}

.gridToggleIcon:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
