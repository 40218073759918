.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image {
  max-width: 95vw; /* Use viewport width units */
  max-height: 95vh;
  object-fit: contain;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeIconContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.closeIcon {
  font-size: 24px;
  color: #333;
}