.aboutContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: 1200px;
  padding: 40px;
}

.profilePhotoContainer {
  flex: 0 0 300px;
  margin-right: 40px;
}

.profilePhoto {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pageTitle {
  font-size: 24px; /* Adjust this value as needed */
  margin-bottom: 20px;
}

.aboutText {
  flex: 1;
  max-width: 800px;
  color: #333;
  line-height: 1.5;
}

.aboutText h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.aboutText ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}
