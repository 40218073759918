.homepageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepageImage {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

@media (max-width: 768px) {
  .homepageContainer {
    height: auto;
    padding-top: 64px; /* Adjust the value as needed */
  }
}


