/* CarouselView.module.css */
.carouselView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.photoImage {
  max-width: 90vw; /* Use 90% of the viewport width */
  max-height: 75vh; /* Use 80% of the viewport height */
  height: auto;
  object-fit: contain;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  align-self: flex-start;
  animation: fadeInOut .5s ease-in-out;
}

.subtitleText {
  font-size: 1rem;
  color: #666;
  margin-top: 0.5rem;
  align-self: flex-start;
}

